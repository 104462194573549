:root {
  --font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  --swidget-pink: #CE0058;
  --progress-red: #C00D1E;
  --progress-yellow: #EBBE00;
  --progress-lime-green: #8BC400;
  --progress-orange: #ED7534;
  --progress-dark-orange: color-mix(in srgb, var(--progress-orange) 95%, black);
  --progress-green: #08807F;
  --progress-dark-green: color-mix(in srgb, var(--progress-green) 75%, black);
  --filter: invert(11%) sepia(71%) saturate(6207%) hue-rotate(328deg) brightness(102%) contrast(101%);
}

* {
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li,
button {
  font-family: var(--font-family);
  margin: 0;
}

.italic {
  font-style: italic;
}

h1 {
  font-size: 36px;
  color: white;
}

h2 {
  font-size: 24px;
  color: white;
  text-align: center;
}

h2.black {
  color: black;
}

h3 {
  font-size: 18px;
  color: white;
}

h3.black {
  color: black;
}

h4 {
  font-size: 16px;
  color: white;
}

h4.black {
  color: black;
}

h4.tab {
  cursor: pointer;
}

h4.selected {
  border-bottom: solid var(--progress-orange) 0.3rem;
}

h5 {
  font-size: 14px;
  font-weight: normal;
  color: white;
}

h5.black {
  color: black;
}

h6 {
  font-size: 12px;
  font-weight: normal;
}

h6.white {
  color: white;
}

span.white {
  color: white;
}

a {
  color: var(--progress-orange);
  text-underline-offset: 2px;
}

a:hover {
  text-decoration: none;
}

a.nostyle {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ol {
  list-style: circle;
  padding: 0;
  margin: 0 0 1rem 1rem;
  line-height: 1.5;
}

.bold {
  font-weight: bold;
}

.centered {
  margin: 0 auto;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.display-linebreak {
  white-space: pre-line;
}

.no-pointer-events {
  pointer-events: none;
}

/* -------------- Margins -------------- */
.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.mb-075 {
  margin-bottom: 0.75rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-15 {
  margin-bottom: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-25 {
  margin-bottom: 2.5rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}
.mt-25 {
  margin-top: 2.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-02 {
  margin-right: 0.2rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.small-span {
  font-size: smaller;
}

/* -------------- Filters -------------- */
.icon-filter-white {
  filter: invert(93%) sepia(7%) saturate(26%) hue-rotate(313deg) brightness(160%) contrast(104%);
}

.icon-filter-green {
  filter: invert(72%) sepia(94%) saturate(803%) hue-rotate(25deg) brightness(84%) contrast(102%);
}

.icon-filter-pink {
  filter: invert(13%) sepia(74%) saturate(6997%) hue-rotate(327deg) brightness(80%) contrast(104%);
}

.icon-filter-yellow {
  filter: invert(64%) sepia(83%) saturate(1317%) hue-rotate(10deg) brightness(104%) contrast(103%);
}

/* -------------- Chevron -------------- */
.chevron {
  width: 8px;
}
.chevron.white {
  filter: invert(91%) sepia(100%) saturate(2%) hue-rotate(87deg) brightness(109%) contrast(98%);
}

.chevron.left {
  transform: rotate(180deg);
}

.chevron.down {
  transform: rotate(90deg);
}

.chevron.flip {
  transform: rotate(-90deg);
}

/* -------------- Headers -------------- */
.left-container header {
  height: 72px;
  margin-bottom: 1.5rem;
}

.right-container header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 4px;
  height: 72px;
  margin-bottom: 1.5rem;
}

.right-container header.archive {
  flex-direction: row;
}

.sub-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.sub-header .left {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.sub-header .left h2 {
  text-align: left;
}

.sub-header .right {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 1rem;
}

.properties {
  align-items: flex-start;
}

.add-phone-container {
  display: flex;
  align-items: center;
}

.add-phone {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 1vw 3vw;
}

.phone-input-container {
  display: flex;
  align-items: end;
}

.phone-input {
  width: 50% !important;
  height: 2.5rem !important;
  margin: 0 1rem;
}

#verification-code {
  width: 10% !important;
}

.phone-buttons-container {
  display: flex;
  justify-content: space-around;
  padding-left: 3vw;
  width: 400px;
}

.property-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* @media screen and (max-width: 961px) {
  .sub-header .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
} */

.sub-header .right .filters {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 37px;
}

.sub-header.dash-tab {
  gap: 0;
  height: 50px;
}

/* .settings-header {
  height: 86px !important;
} */
/* @media screen and (min-width: 1200px) {
  .sub-header.dash-tab {
    height: 49px;
  }
} */

.section-header {
  display: flex;
  justify-content: flex-end;
}


.section-header .right {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.section-header .download-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--progress-orange);
  border: 1px solid #707070;
  height: 37px;
  width: 37px;
}

.section-header .download-btn:hover {
  background-color: var(--progress-dark-orange);
}

/* -------------- Footer -------------- */
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  width: 100%;
  height: 3rem;
  position: fixed;
  left: 0;
  bottom: 0;
  background: black;
}

footer img {
  filter: invert(91%) sepia(100%) saturate(2%) hue-rotate(87deg) brightness(109%) contrast(98%);
  width: 26px;
}

footer alt {
  color: white;
}

/* -------------- Containers -------------- */
.left-container {
  width: 32.5%;
  height: 120%;
  padding: 2rem 0.5rem 2rem 2rem;
  position: absolute;
  top: 0;
}

.right-container {
  width: 67.5%;
  padding: 2rem 2rem 6rem 0;
  position: absolute;
  right: 0;
}

.right-container .settings-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--progress-green);
  border: 1px solid #707070;
  border-radius: 4px;
  height: 47px;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0.5rem;
}

.settings-wrapper h4 {
  cursor: pointer;
  margin-right: 3rem;
}

.settings-wrapper button {
  display: flex;
  padding: 0;
  border: none;
  background: none;
}

.settings-wrapper button img {
  width: 26px;
}

.exceptions-container {
  margin-bottom: 1rem;
}

.cooldown-input {
  width: 20% !important;
  margin-right: 1rem;
}

.right-container .content {
  background-color: var(--progress-green);
  min-height: 650px;
  padding: 1rem 1.5rem;
}

.page-container {
  display: flex;
  flex-direction: column;
}

.main-panel {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 2rem;
}

.main-panel .home-wrapper {
  background-color: var(--progress-green);
  border-radius: 4px;
  color: white;
  padding: 0.5rem;
  min-height: 5rem;
  overflow-y: auto;
}

.home-wrapper .no-devices-wrapper {
  background: #F8F8F8;
  border: 1px solid #707070;
  color: black;
  height: 4rem;
  padding: 4px;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type='search'] {
  border: 2px solid rgb(133, 133, 133);
  outline: none;
}

/* -------------- Tiles -------------- */
.tile-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  grid-gap: 4px;
  background: #F8F8F8;
  border: 1px solid #707070;
  padding: 4px;
}

.tile {
  position: relative;
  background: #FFFFFF;
  font-size: 12px;
  color: #000000;
  text-align: center;
  border: 1px solid #707070;
  border-radius: 4px;
  padding: 4px;
  margin: 4px;
  min-width: 95px;
  height: 78px;
}

.tile-wrapper-2 {
  display: flex;
  height: fit-content;
  background: #F8F8F8;
  border: 1px solid #707070;
  overflow-x: auto;
  /* max-width: 303px; */
}

.tile-wrapper-2 .tile {
  width: 76px;
  height: 76px;
}

.tile h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 6px;
}

.tile span {
  
}

.tile img {
  height: 24px;
  filter: invert(72%) sepia(94%) saturate(803%) hue-rotate(25deg) brightness(84%) contrast(102%);
}

.tile span {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* alert tile */
.tile.alert {
  background: var(--progress-red);
}

/* offline tile */
.tile.offline {
  background: var(--progress-yellow);
}

.tile.uninstalled {
  background-color: gray !important;
}

.tile.offline .warning {
    width: 14px;
    position: absolute;
    bottom: -2px;
    right: 5px;
  }

.tile.alert .warning {
    width: 14px;
    position: absolute;
    bottom: -7px;
    right: 5px;
  }

/* motion tile */
.tile.motion.occupied {
  background: var(--progress-lime-green);
}

.tile.motion span {
  color: black;
}

/* highlighted tile */
.tile.highlight h6 {
  color: white;
  font-weight: bold;
}

.tile.highlight img {
  filter: invert(93%) sepia(7%) saturate(26%) hue-rotate(313deg) brightness(160%) contrast(104%);
}

.tile.highlight span {
  color: white;
}

/* -------------- Dropdown -------------- */
.dropdown-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  color: white;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: var(--progress-dark-green);
  white-space: nowrap;
  height: 37px;
}

.large {
  height: 47px !important;
}

.large h5 {
  color: black;
}

.black h4 {
  color: black;
}

.archived {
  gap: 1rem !important;
}

.archived-row {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.colored {
  color: var(--progress-orange) !important;
  font-size: 16px;
  font-weight: bold;
}

.black-wrapper {
  width: 100%;
  padding: 0.5rem 1rem;
  color: white;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: var(--progress-dark-green);
  white-space: nowrap;
  height: 37px;
}

.dropdown-btn:hover {
  background-color: var(--progress-dark-green);
}

.dropdown-btn:focus {
  background-color: var(--progress-dark-green);
  border-bottom: 0.5px solid darkgrey;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-btn>*,
button:disabled.dropdown-btn {
  pointer-events: none;
}

.main-drop-wrapper {
  position: absolute;
  width: calc(100% - 2.5rem);
  z-index: 2;
}

ul.main-dropdown-options {
  position: relative;
  max-height: 328px;
  overflow-y: auto;
}

ul.dropdown-options {
  position: absolute;
  width: calc(100% - 2.5rem);
  z-index: 2;
}

#dropId {
  gap: 4rem;
}

#dropId+ul {
  position: relative;
  z-index: 2;
}

.nav-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1rem;
}

.nav-links a {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.nav-links a:hover {
  color: var(--progress-orange);
}

li.drop-row,
div.drop-row {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: black;
  font-size: 18px;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  background-color: white;
  border-top: none;
  border-right: 2px solid #707070;
  border-bottom: 0.5px solid darkgrey;
  border-left: 2px solid #707070;
}

li.drop-row:hover,
li.drop-row:focus,
div.drop-row:hover,
div.drop-row:focus {
  background-color: #E8E8E8;
}

li.drop-row:last-child,
div.drop-row {
  border-bottom: 2px solid #707070;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

div.drop-row.fixed-btn {
  background-color: var(--progress-orange);
}

div.drop-row.fixed-btn:hover {
  background-color: var(--progress-dark-orange);
}

/* -------------- Buttons -------------- */
button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.primary-btn {
  cursor: pointer;
  background-color: var(--progress-orange);
  border: 1px solid #707070;
  border-radius: 6px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: bold;
  color: black;
  width: 100%;
  max-width: 180px;
  padding: 0.5rem;
}

.primary-btn:enabled:active,
.primary-btn:enabled:hover {
  outline: none;
  background-color: var(--progress-dark-orange);
}

.primary-btn.small {
  max-width: 100px;
  height: fit-content;
  padding: 4px 1rem;
  font-size: 14px;
}

.primary-btn.long {
  max-width: 220px;
}

.primary-btn.archive {
  background-color: var(--progress-green);
  color: white;
}

.primary-btn.archive:hover {
  background-color: var(--progress-dark-green);
}

.add-btn {
  color: white;
  background-color: rgba(169, 169, 169, 0.3);
  font-size: 14px;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 10px;
  padding: 1px 1rem;
}

.text-btn {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 2px;
  background: none;
  border: none;
  padding: 0;
}

.text-btn:hover {
  text-decoration: none;
}

.text-btn.primary {
  color: var(--progress-dark-green);
}

.text-btn.secondary {
  color: var(--progress-orange);
  text-decoration: none;
}

button.back-btn {
  color: white;
  margin-bottom: 0.5rem;
}

button.back-btn img {
  margin-right: 0.5rem;
}

.edit-btn {
  cursor: pointer;
  height: 24px;
  margin-right: 10px;
  margin-top: 7px;
}

.user-icons {
  filter: invert(0%) sepia(0%) saturate(7474%) hue-rotate(179deg) brightness(95%) contrast(105%);
  height: 1.3rem;
  cursor: pointer;
  padding-bottom: 1px;
}

.icons-container {
  display: flex;
  align-items: baseline;

}

button.alert {
  padding: 0;
  color: var(--progress-orange);
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

button.alert:hover {
  text-decoration: none;
}

button.comment {
  position: relative;
  padding-left: 10px;
}

button.comment img {
  position: absolute;
  top: 2px;
  right: 15px;
}

.bubble-wrapper {
  position: relative;
  top: -7px;
  display: flex;
  justify-content: center;
  z-index: 1;
  visibility: hidden;
}

.bubble-wrapper.visible {
  visibility: visible;
}

.bubble-wrapper .comment-bubble {
  font-size: 18px;
  color: var(--progress-red);
}

.bubble-wrapper span {
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.email-span {
  color: white!important;
  font-size: 14px;
}

/* -------------- Table -------------- */
table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

table thead {
  background-color: var(--progress-dark-green);
  color: white;
  position: sticky; 
  top: -3px; 
  z-index: 2;
}

table thead tr:first-child{
  border: none;
  top: -3px; 
}

table tbody {
  background-color: white;
}

table tr {
  border-bottom: 1px solid #707070;
}

table.alerts-table tr {
  cursor: pointer;
}

table tr.grey {
  background-color: #F2F2F2;
}

table tr.white {
  background-color: white;
  font-weight: bold;
}

table th,
table td {
  padding: 10px;
}

table.alerts-table.wide-screen th:nth-child(1) {
  width: 0.25px;
}
table.alerts-table.wide-screen th:nth-child(2) {
  width: 0.25%;
}

table.alerts-table.wide-screen th:nth-child(3) {
  width: 15%;
}

table.alerts-table.wide-screen  th:nth-child(4),
table.alerts-table.wide-screen  th:nth-child(5) {
  width: 10%;
}

table.alerts-table.wide-screen  th:nth-child(6) {
  width: 37%;
}

table.alerts-table.wide-screen  th:nth-child(7) {
  width: 30%;
}

table.alerts-table.wide-screen  th:nth-child(8) {
  width: 10%;
}

table.alerts-table.wide-screen  td:nth-child(4),
table.alerts-table.wide-screen  td:nth-child(5) {
  white-space: nowrap;
}

table.alerts-table.wide-screen  td:nth-child(7) {
  font-size: 14px;
}

table.alerts-table.wide-screen  td:nth-child(8) {
  text-align: center;
  white-space: nowrap;
}

table button {
  border: none;
  outline: none;
  background: none;
}

table button img {
  width: 20px;
}

table .video-img {
  width: 100%;
  max-width: 10rem;
}

.scrollable-wrapper {
  /* max-height: 28rem; */
  overflow-y: auto;
  overflow-anchor: none;
  background-color: white;
  height: 650px;
}

.schedule-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 1rem;
}

.schedule-table {
  margin: auto;
  width: 95%;
}

.right-container .header-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--progress-green);
  border: 1px 1px 0 solid #707070;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  float: left;
  padding: 1rem 1.5rem 0 1.5rem;
}

.event-video {
  width: 600px !important;
  height: 337.5px !important;
}
/*------------- Events Table -----------------*/
.events-filter {
  display: flex;
  justify-content: space-between;
  width:100%;
}

.left-arrow-icon {
  height: 15px;
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}

.yellow {
  background-color: yellow;
}

.lightgray {
  background-color: lightgray;
}

.threat-tag {
  padding: 1px !important;
}

.threat-context-menu {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 1px 1px #707070;
  padding: 1rem 0.5rem;
  z-index: 5;
  cursor: pointer;
  width: 250px;
}

.threat-context-menu h4 {
  text-align: center;
  margin-bottom: 0.5rem;
}

.threat-context-menu li {
  padding: 0.5rem;
  border: 1px solid darkgray;
  margin-bottom: 0.5rem;
  font-size: 12px;
  text-align: center;
}
.threat-context-menu li:first-child {
  background-color: red;
}

.threat-context-menu li:nth-child(2) {
  background-color: orange;
}

.threat-context-menu li:nth-child(3) {
  background-color: yellow;
}

.threat-context-menu li:nth-child(4) {
  background-color: lightgray;
}

.events-checkbox {
  accent-color: var(--progress-orange);
  -ms-transform: scale(1); /* IE */
  -moz-transform: scale(1); /* FF */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1); /* Opera */
  transform: scale(1);
}

.select-cell {
  display: flex;
}

.events-table-container {
  overflow: auto;
}

.events-table-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 3;
}

.events-table-banner button {
  padding-right: 2rem;
  border: none;
  color: white;
  background-color: var(--progress-green);
  font-weight: bold;
}

.banner-icons {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  height: 12px;
  margin-right: 4px;
}

#tag-button, #mark-as-read {
  margin-bottom: -2px;
}

#tags-filter {
  justify-content: flex-end;
}

.select-icon {
  margin-bottom: -2px;
}

.events-table-banner button:disabled {
  opacity: 0.5;
}

.selected-number {
  color: white;
  margin: 0 0.5rem;
  font-size: 14px;
}

.right-buttons {
  padding-right: 0 !important;
}

.white-icon {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(66deg) brightness(106%) contrast(101%);
}
/*------------- Account Details tab -----------*/
.account-details-row {
  padding: 1vw 3vw;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 50%;
}

.notification-control-container {
  padding: 1vw;
  display: flex;
}
/* .email-notification span {
  color: black !important;
} */
/* ------------- Activity Tab -------------*/
.events-type-filter {
  align-self: center;
  margin: 1rem 0;
  height: 50px;
}

.events-type-filter ul {
  position: relative;
  z-index: 4 !important;
}

.filter-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
/* -------------- Address tab -------------- */
.modifier-container{
  padding: 0.5rem;
  background:lightgray;
  color: black;
  visibility: hidden;
  opacity: 0;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  font-size: 10px;
  margin-top: 0.2rem;
  transition: opacity .2s, visibility .2s;
}

.alert-toggle-conatiner:hover .modifier-container {
  visibility: visible;
  opacity: 1;
}
#room-name {
  height: 30px;
}

#room-name::after {
  content:url('./assets/edit.svg');
}

#new-room-name {
  color: white;
  font-size: 24px;
  outline: 1px solid white;
  background: none;
  border: none;
  padding: 0 0.5rem;
  height: 30px;
  width: 100%;
}

#room-error.error-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1rem;
}

#room-error.error-message svg {
  font-size: 18px;
}

.room-link {
  color: var(--progress-orange);
  text-decoration: underline;
  cursor: pointer;
}

.video-info {
  display: flex;
  justify-content: space-between;
}

.signal-img {
  margin-bottom: -2px;
}

.share-link-popup {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;
}

.share-btn {
  text-decoration: none;
}

.shared-link-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0.5rem;
}

.baseline {
  align-items: baseline;
}

.shared-link-container img {
  filter: var(--filter);
}

.shared-link {
  border: 2px solid black;
  padding: 0.5rem;
  width: 100%;
  margin-right: 0.3rem;
}

.timer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.timer button{
  height: 2.5rem !important;
}

.timer-input {
  display: flex;
  align-items: baseline;
}

.timer-input input {
  margin: 0 0.5rem;
  width: 70px !important;
  height: 2.5rem !important;
}

.confirm-copy {
  color: var(--progress-orange);
}

.share-btn {
  margin-bottom: 2rem;
}

.share-btn img {
  margin-bottom: -2px;
}

.share-info-icons {
  height: 20px;
  filter: var(--filter);
}

.shared-link-info-container {
  display: grid;
  grid-template-columns: 4fr 4fr 7fr 1fr;
  grid-template-rows: 1fr;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.no-wrap {
  white-space: nowrap;
}

#link-name {
  width: 150px !important;
}

.clickable {
  cursor: pointer;
}

.time-component-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;
}

.white-wrapper {
  background-color: white;
  padding: 3rem 1.5rem;
  margin-bottom: 2.5rem;
}

.video-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

.landscape-mode {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  gap: 1rem;
}

.landscape-mode h3 {
  color: black;
  text-align: center;
}

.public-page .video-wrapper>div {
  width: 100%;
  max-width: 600px;
}

.public-page .time-btn-container button {
  background-color: var(--swidget-pink);
}

.public-page .time-btn-container button:hover {
  background-color: var(--swidget-pink);
}

.video-wrapper>div {
  width: 100%;
  max-width: 25rem;
}

.video-wrapper h3 {
  color: black;
  text-align: center;
}

.video-wrapper .container {
  position: relative;
}

.public-page .video-wrapper .container video {
  height: 337.5px;
}


.video-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.video-player-wrapper {
  position: relative;
  width: 400px;
  height: 225px;
  align-self: center;
  overflow: hidden;
  transform-origin: center;
}

.video-controls {
  background-color: black;
  margin-top: -5px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-controls img {
  cursor: pointer;
}

.video-controls span {
  width: 200px;
  margin: 0 10px;
}

.video-btn-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: none;
  background: none;
  padding: 0;
}

.video-btn-wrapper img {
  width: 60px;
  opacity: 0.5;
}

.video-btn-wrapper span {
  color: white;
  font-weight: bold;
}

.cam-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.cam-name img {
  width: 18px;
}

.sensor-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.sensor-wrapper .tile {
  height: 100px;
  width: 110px;
  padding: 10px 4px;
}

.sensor-wrapper .tile img {
  height: 36px;
  margin-bottom: 3px;
}

.sensor-wrapper .tile.offline .warning {
  bottom: -10px;
}

#archive-banner {
  color: white;
  background-color: var(--progress-lime-green);
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 4px 0.5rem;
  border: 1px solid #707070;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#archive-banner+.content {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

span.archived {
  color: var(--progress-orange);
}

.error {
  color: var(--swidget-pink);
}

.text-center {
  text-align: center;
}

.alerts-toggle {
  margin-left: 1rem;
  font-weight: bold;
}

#silent-icon {
  height: 1rem;
  filter: var(--filter);
  margin-right: 0.2rem; 
}

.date-time-filter-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin: 1rem 0;
}

.from-to {
  width: 50px;
}

.date-time-filter-container input {
  background-color: white;
}

/* .date-time-filter-container svg {
  color: white;
} */

.clear-filter {
  margin: 0.5rem 0;
  max-width: 120px;
  align-self: end;
}

/* --------------- Public Page ------------ */
.public-page-continaner {
  padding: 1rem;
}

.public-page {
  padding: 1rem;
}

.public-page .logo {
  height: 72px;
}

/* -------------- Support tab -------------- */
.white-wrapper.support {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.white-wrapper.support .primary-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 180px;
}

/* -------------- 2FA tab -------------- */
.section-container {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.black-rounded-tile {
  background-color: black;
  border-radius: 4px;
  padding: 1rem;
}

.black-rounded-tile hr {
  border: none;
  height: 1px;
  background-color: #282828;
  margin: 1rem 0;
}

/* -------------- Switch -------------- */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--progress-red);
  border-radius: 34px;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--progress-orange) !important;
}

input:focus+.slider {
  box-shadow: 0 0 1px #4CAF50;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.switch.disabled {
  pointer-events: none;
}

.switch.disabled .slider:before {
  background-color: dimgrey;
}

/* -------------- Progress bar -------------- */
input[type=range] {
  -webkit-appearance: none;
  height: 25px;
  width: 100%;
  background: transparent;
  margin: 0 0 0.2rem 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #FFFFFF;
  border-radius: 1px;
  border: 0px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #FFFFFF;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #FFFFFF;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #FFFFFF;
  border-radius: 1px;
  border: 0px solid #000000;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #FFFFFF;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #FFFFFF;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
  background: #FFFFFF;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #FFFFFF;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #FFFFFF;
}

input[type=range]:focus::-ms-fill-upper {
  background: #FFFFFF;
}
/*-------------------Time Picker----------------*/
#time-picker fieldset {
  border: none;
  box-shadow: none;
}

#time-picker button {
  color: var(--progress-green);
} 

#date-picker fieldset {
  border: none;
  box-shadow: none;
}

#date-picker button {
  color: var(--progress-green);
} 

.date-time-container {
  display: flex;
  align-items: center;
}
/*-----------------------------------------*/
.header-link {
  color: var(--progress-orange);
  cursor: pointer;
  margin-top: 0.5rem;
}

.delete {
  height: 1rem;
  filter: var(--filter);
  padding-left: 2rem;
  cursor: pointer;
}

.comment-time {
  vertical-align: top;
}

.radio-button-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.radio-button-container label {
  color: white;
  font-weight: bold;
}

.radio-button-group {
  display: flex;
  flex-direction: row;
}

.context-menu {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 1px 1px #707070;
  padding: 1rem 2rem;
  z-index: 5;
  cursor: pointer;
}

.tile-context-menu {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 1px 1px #707070;
  z-index: 5;
  cursor: pointer;
}

.tile-context-menu li:first-child{
  border-bottom: 1px solid grey;
}

.tile-context-menu li {
  padding: 0.5rem;
}

.tile-context-menu li:hover {
  background-color: lightgray;
}

/* Scrollbar - Firefox */
/* .sensor-wrapper,
.security-wrapper {
  scrollbar-width: thin;
  scrollbar-color: rgba(169, 169, 169, 0.3);
} */
/* Chrome, Edge, and Safari */
/* .sensor-wrapper::-webkit-scrollbar,
.security-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
} */
/* .sensor-wrapper::-webkit-scrollbar-thumb,
.security-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.3);
  border-radius: 20px;
} */

/* -------------- Spinning loader -------------- */
.loader {
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader.dark {
  border: 2px solid var(--progress-dark-green);
  border-bottom-color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* -------------- Media queries --------------
@media screen and (min-width: 1600px) {
  .left-container {
    padding-left: 6rem;
  }

  .right-container {
    padding-right: 6rem;
  }

  .main-drop-wrapper,
  ul.dropdown-options {
    width: calc(100% - 6.5rem);
  }
} */
table.alerts-table.small-screen th:nth-child(1) {
  width: 0.5%;
}

table.alerts-table.small-screen th:nth-child(2) {
  width: 0.5%;
}

table.alerts-table.small-screen th:nth-child(3) {
  width: 15%;
}

table.alerts-table.small-screen th:nth-child(4) {
  width: 43%;
}

table.alerts-table.small-screen th:nth-child(5) {
  width: 29%;
}

table.alerts-table.small-screen th:nth-child(6) {
  width: 10%;
}

table.alerts-table.small-screen td:nth-child(3) {
  white-space: nowrap;
}

table.alerts-table.small-screen td:nth-child(6) {
  text-align: center;
  white-space: nowrap;
}


@media screen and (min-width: 1146px) {
  .small-screen {
    display: none;
  }
}
@media screen and (max-width: 1145px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 15px;
  }
  h4 {
    font-size: 13px;
  }
  h5 {
    font-size: 11px;
  }
  h6 {
    font-size: 9px;
  }

  table {
    overflow-x: auto;
  }

  td {
    font-size: 11px;
  }

  th{
    font-size: 13px;
  }

  p {
    font-size: 13px;
  }

  li {
    font-size: 13px;
  }

  .wide-screen {
    display: none;
  }

  .alert-table-icons {
    display: flex;
  }
  
  #date-picker input {
    font-size: 11px;
  }

  #time-picker input {
    font-size: 11px;
  }

  
  .dropdown-btn {
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    gap: 1rem;
    font-size: 13px;
  }
  .white-wrapper.support img {
    height: 45px;
  }

  #dropId {
    white-space: normal;
    gap: 1rem;
  }

  .sub-header .right {
    justify-content: flex-end;
  }

  .sub-header .properties {
    flex: wrap;
  }

  .sub-header .kit {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .content .kit-tab {
    flex-wrap: nowrap !important;
  }

  .primary-btn {
    max-width: 100px;
  }

  .settings-wrapper h4 { 

    margin-right: 1.5rem;
  }

  .radio-button-container {
    margin-left: 3px;
  }

  .radio-button-container label {
    font-size: small;
  }

  .left-container {
    padding-left: 1rem;
  }

  .tile {
    min-width: 75px;
    height: 75px;
  }
  .section-header {
    display: flex;
    justify-content: space-between;
  }

  .section-header>div {
    flex: none;
  }

  .right-container .content {
    padding: 1rem 1rem;
  }

  .sub-header {
    gap: 0.5rem;
  }

  .white-wrapper.support .primary-btn {
    gap: 0;
    height: 120px;
  }

  .add-phone button {
    font-size: small !important;
    padding: 0.3rem !important;
  }

  .add-phone input::placeholder {
    font-size: small;
  }

  .add-phone input {
    font-size: small !important;
    width: 45%!important;
    padding: 0 0.5rem !important;
  }

  .add-phone {
    align-items: center !important;
  }

  .add-phone h5 {
    width: 30%;
  }

  .event-video {
    width: 400px !important;
    height: 225px !important;
  }

  .shared-link-info-container {
    display: block;
  }

  .share-info-icons {
    height: 15px;
    margin-top: 5px;
  }
}

@media  screen and (max-width: 800px) {
  .notification-control-container {
    flex-direction: column;
  }

  .event-video {
    width: 222px !important;
    height: 125px !important;
  }

  .public-page .logo {
    height: 50px;
  }
  
}

@media screen and (max-width: 565px) {
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 12px;
  }
  h3 {
    font-size: 10px;
  }
  h4 {
    font-size: 9px;
  }
  h5 {
    font-size: 7px;
  }
  h6 {
    font-size: 6px;
  }

  p {
    font-size: 10px;
  }

  th {
    font-size: 10px;
  }

  table {
    display: block;
    overflow-x: auto;
  }

  .tile {
    min-width: 45px;
    height: 45px;
    padding: 0;
  }

  .tile span {
    font-size: 6px;
    margin-top: -2px;
  }

  .tile-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(45px, 1fr));
  }

  .tile h6 {
    margin-bottom: 0;
  }

  #dropId {
    gap: 0.5rem;
    white-space: normal;
  }

  .chevron {
    width: 5px;
  }

  .dropdown-btn h4 {
  font-size: 7px;
  }

  .nav-links a {
    font-size: x-small; 
  }

  .search-bar {
    height: 30px;
  }
  .search-bar::placeholder {
    font-size: x-small;
  }

  .right-container .settings-wrapper {
    padding: 0.25rem 0.75rem;
  }

  .settings-wrapper h4 {
    margin-right: 0.6rem;
  }

  .add-phone-container {
    flex-direction: column;
  }

  .add-phone button {
    font-size: xx-small !important;
    padding: 0.1rem !important;
  }

  .add-phone {
    flex-direction: column;
    gap: 0 !important;
  }

  .add-phone input {
    font-size: 10px !important;
    width: fit-content!important;
    padding: 0 0.2rem !important;
  }

  .add-phone h5 {
    width: 100%;
    font-size: 8px;
  }

  #time-picker input {
    font-size: 6.5px;
  }

  .radio-button-container {
    height: 20px;
  }

  .radio-button-container label {
    font-size: 10px;
  }

  .radio-button-group {
    flex-direction: column;
  }

  .sub-header .properties {
    align-items: flex-start;
    justify-content: space-between;
  }

  .content .kit-tab {
    flex-direction: column;
  }

  .sub-header .kit {
    flex-direction: row;
    align-items: baseline;
  }


  .right-container header {
    margin-bottom: 0.5rem;
    gap: 1px;
    height: 60px;
  }

  .left-container header {
    margin-bottom: 0.5rem;
    height: 60px;
  }

  .primary-btn {
    max-width: 70px;
    font-size: 12px;
    padding: 0.2rem;
  }

  .tile-wrapper-2 .tile {
    width: 45px;
    height: 45px;
  }

  .tile {
    margin: 2px;
  }
  .section-header .download-btn {
    height: 25px;
    width: 25px;
  }

  .activity-log {
    align-items: end;
  }

  .white-wrapper.support .primary-btn {
    height: 60px;
    width: 60px;
  }
  .white-wrapper.support img {
    height: 25px;
  }
  .alerts-toggle {
    font-size: small;
  }
}

@media screen and (max-width: 630px) {
  table {
    display: block;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1200px) {
  .link {
    padding: 0.2rem;
    font-size: 12px;
  }

  .timer button {
    padding: 0.25rem;
    font-size: 12px;
  }

  .timer {
    display: flex;
    flex-direction: column;
  }
  .timer-input {
    margin-bottom: 1rem;
  }

  #hours {
    margin-left: 1.25rem;
  }
}
@media screen and (min-width: 1800px) {
  .settings-header {
    flex-direction: column;
  }
}