.circle-mode {
  font-size: 25px;
  color: peru;
}

.circle-rule {
  font-size: 25px;
  color: #4AC9E3;
}

.circle-schedule {
  font-size: 25px;
  color: #87027B;
}

.chosen {
  font-weight: bold;
  color: #ce0058;
}

table .first-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

table .last-cell {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

table .icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

table .icon-wrapper img {
  position: absolute;
  width: 1rem;
}
