.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.inner-container {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2rem 1.5rem;
  min-height: 680px;
  width: 100%;
  max-width: 450px;
}

.inner-container img {
  width: 100%;
  max-width: 250px;
  margin: 0 auto 2rem auto;
}

.inner-container h1.login {
  font-size: 26px;
}

.inner-container h2.login {
  font-size: 20px;
}

.inner-container h1.login,
.inner-container h2.login {
  color: black;
  text-align: center;
  margin-bottom: 2rem;
}

.inner-container div.error-message {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #ce0058;
  margin-bottom: 2rem;
}

.inner-container div.error-message svg {
  font-size: 18px;
}

.inner-container label {
  margin-bottom: 0.5rem;
}

.inner-container p {
  margin-bottom: 0.5rem;
}

.inner-container .input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.inner-container .btn-wrapper .login-btn {
  max-width: none;
  font-size: 20px;
  padding: 15px;
}

.inner-container .back-btn {
  text-align: left;
  width: fit-content;
  padding: 0;
  margin-bottom: 1rem;
}

input[type='email'],
input[type='password'],
input[type='number'],
input[type='text'],
textarea,
select {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border: 1px solid #707070;
  border-radius: 4px;
  height: 3rem;
  width: 100%;
  padding: 0 1rem;
}

input[type='email']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='text']:focus,
select:focus {
  outline: none;
  border: 2px solid #ce0058;
}

input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='number']::placeholder,
input[type='text']::placeholder {
  color: #AAAAAA;
}

.inner-container .btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.inner-container .swidget-pink-btn {
  cursor: pointer;
  background-color: #ce0058;
  border: none;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: white;
  width: 100%;
  max-width: 150px;
  padding: 10px 15px;
}

.inner-container .swidget-pink-btn:active,
.inner-container .swidget-pink-btn:hover {
  outline: none;
  background-color: #ae004a;
}

.inner-container .swidget-pink-btn:disabled {
 opacity: 0.6;
 cursor: not-allowed;
}

.inner-container .copyright-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  margin-top: 6rem;
}

.inner-container .copyright-wrapper .link {
  font-size: 14px;
  color: #707070;
}

.inner-container .link {
  color: #707070;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  text-decoration: underline;
  text-underline-offset: 2px;
  background: none;
  border: none;
}

.inner-container .link:hover {
  color: black;
}

.inner-container .password-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inner-container .password-wrapper svg {
  cursor: pointer;
  position: absolute;
  padding: 1rem;
}

/* Checkbox */
.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain:hover span {
  color: black;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.5;
  font-size: 1rem;
  font-family: inherit;
  color: #707070;
}

.b-contain {
  display: table;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  margin-bottom: 1.5rem !important;
}

.b-contain input[type="checkbox"]~.b-input {
  position: absolute;
  top: 2px;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: rgb(255, 255, 255);
  transition: background 250ms;
  border: 1px solid #707070;
  border-radius: 0.2rem;
}

.b-contain input[type="checkbox"]~.b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 0.3rem;
  height: 0.6rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.b-contain input[type="checkbox"]:disabled~.b-input::after {
  border-color: #ffffff;
}

.b-contain input:checked~.b-input::after {
  display: block;
}

.b-contain:hover input[type="checkbox"]:not([disabled])~.b-input,
.b-contain input[type="checkbox"]:focus~.b-input {
  background: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 0);
}

.b-contain input:focus~.b-input {
  box-shadow: 0 0 0 2px #60a5fa;
}

.b-contain input[type="checkbox"]:checked~.b-input {
  background: #ce0058;
  border-color: rgb(0, 0, 0);
}

.b-contain input[type="checkbox"]:disabled~.b-input {
  opacity: 0.5;
  cursor: not-allowed;
}

.b-contain:hover input[type="checkbox"]:not([disabled]):checked~.b-input,
.b-contain input[type="checkbox"]:checked:focus~.b-input {
  background: #ce0058;
  border-color: rgb(0, 0, 0);
}

@media screen and (min-width: 600px) {
  .inner-container {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
}

/* Animated Input Field */
.animated-field {
  position: relative;
}

.animated-field label::before {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 1rem;
  line-height: 46px;
  font-size: 16px;
  color: #707070;
  transition: 300ms all;
}

.animated-field input {
  box-sizing: border-box;
  line-height: 46px;
}

.animated-field input:focus {
  outline: 0;
  border-color: #ce0058;
}

.animated-field input:not(:placeholder-shown)~label::before,
.animated-field input:focus~label::before {
  line-height: 20px;
  font-size: 14px;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  background: white;
}

.animated-field input:not(:placeholder-shown)~label::before {
  content: attr(data-title);
}

.animated-field input:focus~label::before {
  color: #ce0058;
}

.animated-field svg {
  position: absolute;
  right: 1rem;
  height: 1rem;
  cursor: pointer;
  margin: 1rem 0;
}

input:checked+.slider {
  background-color: #CE0058;;
}

.slider {
  background-color: lightgray;
}

.code {
  width: 40%;
  height: 40px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-color: rgb(250, 247, 247);
  border: 1px solid darkgray;
  border-radius: 4px;
  text-align: center;
  font-size: medium;
}

.code:focus {
  background-color: white;
  border: 1px solid darkgray;
  outline: none;
}

.code::placeholder {
  text-align: center;
  font-size: medium;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pink {
  color: #ce0058 !important;
}

.accept-btn {
  background-color: #ce0058 !important;
}

.reject-btn {
  background-color: #707070 !important;
}