#archive-popup.popup,
#assign-kit-popup,
#view-event-popup.popup,
#comment-popup.popup,
#download-popup.popup,
#add-property-popup.popup,
#settings-popup.popup,
#general-popup.popup,
#settings-close-popup.popup {
  display: flex;
}

.popup {
  display: none;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  margin: auto;
  padding: 2rem 1rem;
  width: 95%;
  max-width: 45rem;
  border: 4px solid var(--progress-green);
  border-radius: 4px;
}

.confirm-message {
  font-size: 16px;
  margin: 2rem 0;
  width: 70%;
}

.kit-list{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 20rem;
  list-style-type: none;
}

.confirm-cancel-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.kit-list-items{
  width: 100%;
  padding: 0.8rem 1rem;
  background-color: white;
  border: 1px solid #707070;
  cursor: pointer;
}

.kit-list-items:hover {
  background-color: lightgray;
}

.pressed {
  background-color: darkgray !important;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 14px;
  cursor: pointer;
}

#add-user-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 1.5rem;
}

#add-user-container input {
  padding: 1rem;
  margin-bottom: 1rem;
  cursor:pointer;
  background-color: #F1F2F3;
  border-radius: 5px;
}

#add-user-container input::placeholder {
  font-weight: bold;
  font-size: large;
  opacity: 0.5;
  color: gray;
}

#add-user-container input[type=text]:focus {
  border: 2px solid rgb(133, 133, 133);
  outline: none;
}

.users-levels-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 1.2rem;
  cursor: pointer;
  background-color: #F1F2F3 !important;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 5px;
}

.users-levels-options h4 {
  color: black;
  padding: 1rem 0;
  font-size: large;
  font-weight: bold;
}

.users-levels-options span {
  font-weight: bold;
  font-size: large;
  opacity: 0.5;
  color: gray;
}

.add-user-error {
  margin-top: -0.7rem;
  margin-bottom: 0.5rem;
}
/* View Event Popup */
#view-event-popup .popup-content {
  align-items: flex-start;
  text-align: left;
  width: 100%;
  max-width: 75%;
  padding: 2rem 4rem;
}

#view-event-popup .wrapper {
  display: flex;
  gap: 2rem;
  width: 100%;
}

#view-event-popup .left-side {
  flex: 60%;
}

#view-event-popup .right-side {
  flex: 40%;
}

#view-event-popup .video-img {
  width: 100%;
}

#view-event-popup .btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

#view-event-popup .primary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

#view-event-popup .btn-wrapper img {
  width: 20px;
}

/* Comment Popup */
#comment-popup .popup-content {
  align-items: flex-start;
  text-align: left;
  width: 100%;
  max-width: 75%;
  padding: 2rem 4rem;
}

#comment-popup .input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

#comment-popup input[type='text'] {
  border: none;
}

#comment-popup button {
  position: absolute;
  right: 4.5rem;
  border: none;
  outline: none;
  background: none;
}

#comment-popup #send-btn {
  width: 20px;
}

/* Add Property Popup */
.popup .input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 60%;
  text-align: left;
}

.popup .input-group div[data-lastpass-icon-root] {
  display: none;
}

.popup input[type='text'] {
  background-color: whitesmoke;
  height: 2.5rem;
}

.popup input[type='text']:focus,
.popup select:focus {
  outline: none;
  border: 2px solid var(--progress-orange);
}

.popup textarea {
  background-color: whitesmoke;
}

.popup .drop-wrapper {
  position: relative;
}

#add-property-popup .dropdown-btn {
  background-color: whitesmoke;
  color: black;
  font-size: 16px;
}

#add-property-popup .dropdown-btn:focus {
  border: 2px solid var(--progress-orange);
  outline: none;
}

#add-property-popup .chevron {
  filter: none;
}

.popup li.drop-row {
  background-color: white;
  color: black;
  font-size: 16px;
}

.popup li.drop-row:hover {
  background-color: whitesmoke;
}

.popup ul.dropdown-options {
  width: 100%;
  text-align: left;
}

.popup li.drop-row h5 {
  color: black;
}

#add-property-popup div.error-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--swidget-pink);
  margin-bottom: 0.5rem;
}

#add-property-popup div.error-message svg {
  font-size: 18px;
}

/* Settings Popup */
#settings-popup .popup-content {
  align-items: flex-start;
  text-align: left;
  max-width: 70rem;
  padding: 2rem 4rem;
}

#settings-popup .input-group {
  width: 40%;
}

#settings-popup table {
  table-layout: fixed;
  text-align: center;
}

#settings-popup table th,
#settings-popup table td {
  border: 1px solid #707070;
}

#settings-popup table th {
  cursor: default;
}

#settings-popup table td {
  cursor: pointer;
  padding: 0;
  margin: 0;
}

#settings-popup .exception-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

#settings-popup .exception-wrapper button {
  margin-left: 2rem;
}

#settings-popup .add-exception-wrapper {
  display: flex;
  gap: 1rem;
}

#settings-popup .add-exception-wrapper h4 {
  margin-bottom: 4px;
}

#settings-popup .add-exception-wrapper>div:last-child {
  align-self: flex-end;
}

#settings-popup .add-exception-wrapper button {
  height: 2.5rem;
}

#settings-popup .save-btn {
  align-self: flex-end;
}

#settings-popup table input[type='text'] {
  border: none;
  background: none;
  height: auto;
  color: var(--progress-orange);
  font-weight: bold;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

#settings-popup table input[type='text']:focus {
  background-color: #F2F2F2;
}

#settings-close-popup button {
  width: 40%;
  max-width: none;
}

.buttons-group {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

@media screen and (max-width: 1200px) {
  #view-event-popup .video-img {
    width: 20px;
    height: 20px;
  }

  #view-event-popup .left-side .video-img {
    width: 240px !important;
    height: 135px !important;
  }

  #view-event-popup .primary-btn {
    font-size: 11px;
    gap: 0.5rem;
  }

  #view-event-popup .btn-wrapper {
    gap: 0.3rem;
  }

  .popup-content {
    max-width: 30rem;
  }

  #add-user-container input {
    padding: 0.75rem;
  }

  #add-user-container input::placeholder {
    font-size: medium;
  }

  .users-levels-options span {
    font-size: medium;
  }
}


@media screen and (max-width: 600px) {

  #view-event-popup .popup-content {
    padding: 0.1rem 0.2rem;
    padding: 1.5rem 0.75rem;
  }

  #view-event-popup .video-img {
    width: 10px;
    height: 10px;
  }

  #view-event-popup .left-side .video-img {
    width: 168px !important;
    height: 95px !important;
  }

  #view-event-popup .primary-btn {
    font-size: 10px;
    gap: 0.2rem;
  }

  #view-event-popup .btn-wrapper {
    gap: 0.1rem;
  }

  #view-event-popup .wrapper {
    gap: 1rem;
  }
  #view-event-popup .primary-btn {
    font-size: 8px;
    gap: 0.2rem;
  }

  #view-event-popup .primary-btn svg {
    width: 2px;
  }

  #view-event-popup .btn-wrapper {
    gap: 0.1rem;
  }

  #view-event-popup .btn-wrapper img {
    width: 15px;
  }

  #settings-popup table input[type='text'] {
    padding: 0 !important;
  }

  #settings-popup .add-exception-wrapper {
    flex-direction: column;
    gap: 1;
  }

  #settings-popup .add-exception-wrapper>div:last-child {
    align-self: flex-start;
  }

  #settings-popup .save-btn {
    align-self: flex-start;
  }

  .save-btn {
    padding: 0.2rem;
    margin-top: 0.2rem;
  }

  #settings-popup .add-exception-wrapper input {
    font-size: small;
    height: auto;
  }

  .popup-content {
    max-width: 20rem;
    padding: 1rem 0.5rem;
  }

  #add-user-container input {
    padding: 0.5rem;
  }

  #add-user-container input::placeholder {
    font-size: small;
  }

  .users-levels-options span {
    font-size: small;
  }
  }