.left-container .logo {
  height: 100%;
  cursor: pointer;
}

.left-container .search-bar {
  background-color: #F2F2F2;
  border: 1px solid #707070;
  border-radius: 4px;
  width: 100%;
  height: 47px;
  color: black;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.left-container .search-bar:focus {
  border: 2px solid #707070;
}

.left-container .search-bar::placeholder {
  color: #707070;
}

.dash-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

.dash-tiles a {
  color: white;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.5);
  height: 12rem;
}

.dash-tiles a h3 {
  font-weight: normal;
  background: black;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 961px) {
  .left-container .logo {
    height: 60%;
  }
}

@media screen and (max-width: 550px) {
  .left-container .logo {
    height: 40%;
  }
}